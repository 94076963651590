import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './NewsCard.css';

const NewsCard = (props) => {
  const { date, slug, thumbnail, title, type, excerpt, url} = props;
  if (type === "link") {
    return (
      <ComponentWrapper className={`c-NewsCard link`}>
        <a href={props.url} target="_blank" rel="noreferrer">
          <div className="thumbnail">
            <img src={thumbnail} alt={title} />
          </div>
          <div className="info">
            <h4>{title}</h4>
            <div className="meta">
              <strong>link</strong>
              <div className="date"><i className="icon-clock" />{date}</div>
            </div>
          </div>
        </a>
      </ComponentWrapper>
    );
  }
	return (
    <ComponentWrapper className={`c-NewsCard ${type === 'text' ? 'news' : 'video' }`}>
      <Link to={`/${type === 'video' ? 'vods' : 'news'}/${slug}`}>
        <div className="thumbnail">
          { type === 'text' ?
            <img src={thumbnail} alt={title} />
          :
            <div>
              <i className="icon-play" />
              <img src={thumbnail} alt={title} />
            </div>
          }
        </div>
        <div className="info">
          <h4>{title}</h4>
          <div className="meta">
            <strong>{type === 'text' ? 'news' : 'video'}</strong>
            <div className="date"><i className="icon-clock" />{date}</div>
          </div>
          <p dangerouslySetInnerHTML={{__html: excerpt}} />
        </div>
      </Link>
    </ComponentWrapper>
	); 
};

NewsCard.propTypes = {
  date: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
}

export default NewsCard;