import React, { Component } from 'react';
import { Link } from 'gatsby';
import { ComponentWrapper } from './Article.css';
import { formatDistanceToNow } from 'date-fns';

class Article extends Component {

  constructor(props) {
    super(props);

    this.state = {
      article: null
    };
  }

  wrapTableElements = () => {
    let el = document.getElementsByTagName('table');
    if (el.length > 0) {
      for (var i = 0; i < el.length; i++){
        let wrapper = document.createElement('div');
        el[i].parentNode.insertBefore(wrapper, el[i]);
        wrapper.className = 'table-wrapper';
        wrapper.appendChild(el[i]);
      }
    }
  }

  componentDidMount() {
    fetch(`https://cms-esports.smitegame.com/wp-json/wp-api/get-post/1?slug=${this.props.slug}`)
    .then(response => response.json())
    .then(response => {
      console.log
      this.setState({article: response});
    });
  }

  componentDidUpdate() {
    this.wrapTableElements();
  }

  render() {
    const { article } = this.state;
    return (
      <ComponentWrapper className={`c-Article`}>
      {article &&  
        <div className="news-container read">
          {article.format !== 'video' && 
          <header style={{backgroundImage: `url('${article.featured_image}')`}}></header>
          }
          <section className="post" style={{paddingTop: (article.format === 'video' ? '3rem' : '0')}}>
            <div className="post__meta-info">
              <h2>{article.title}</h2>
              <div className="post__meta-social">
                <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://esports.smitegame.com/news/${this.props.slug}`} className="facebook"><i className="icon-facebook"></i></a>
                <a target="_blank" href={`https://twitter.com/home?status=https://esports.smitegame.com/news/${this.props.slug}`} className="twitter"><i className="icon-x"></i></a>
              </div>
              <time>{formatDistanceToNow(new Date(article.timestamp.split(' ')[0]),{addSuffix: true})}</time>
              <div className="post__meta-tags">
              TAGS:
                {article.real_categories && article.real_categories.split(',').map((tag) =>
                  <Link key={tag} to={`/news?s=${tag.replace(/\s+/g, '-').toLowerCase()}`}> {tag}</Link>
                )}
              </div>
            </div>
            {article.hasOwnProperty('video_id') ?
            (<iframe src={`https://www.youtube.com/embed/${article.video_id}?rel=0&amp;showinfo=0`}frameBorder="0" allowFullScreen></iframe>)
            :
            (<article dangerouslySetInnerHTML={{__html: article.content}} />)
            }
          </section>
        </div>
      }
      </ComponentWrapper>
    );
  }
}

export default Article;