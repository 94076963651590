import React, { Component } from 'react';
import withContext from 'store/withContext';
// import { Link } from 'gatsby';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';


// import { l, changeLanguage, LocaleContext } from '../store/localeProvider';

import Article from 'components/News/Article';
import NewsCard from 'components/News/NewsCard';

const PageWrapper = styled.div`
  /* padding: 7.3rem 0; */
  .news-container {
    margin: 0 auto;
    /* max-width: 156rem; */
    padding: 5.8rem 0;
    width: 100%;
    &.read {
      background: var(--background);
      padding-top: 0;
    }
  }
  .loading-wrapper {
    margin-top: 11rem;
    position: relative;
    width: 100%;
  }
  #loader {
    animation: animate 1.5s linear infinite;
    clip: rect(0, 80px, 80px, 40px); 
    height: 80px;
    width: 80px;
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
  }
  @keyframes animate {
    0% { 
      transform: rotate(0deg)
    }
    100% { 
      transform: rotate(220deg)
    }
  }
  #loader:after {
    animation: animate2 1.5s ease-in-out infinite;
    clip: rect(0, 80px, 80px, 40px);
    content:'';
    border-radius: 50%; 
    height: 80px;
    width: 80px;
    position: absolute; 
  } 
  @keyframes animate2 {
    0% {
      box-shadow: inset var(--primary) 0 0 0 17px;
      transform: rotate(-140deg);
    }
    50% {
      box-shadow: inset var(--primary) 0 0 0 2px;
    }
    100% {
      box-shadow: inset var(--primary) 0 0 0 17px;
      transform: rotate(140deg);
    }
  }
  .center-wrapper {
    align-items: flex-start;
    background: var(--background);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 156.6rem;
    min-height: 100vh;
    padding: 5.8rem;
    width: 100%;
    .inner-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 96rem;
      width: 100%;
      @media screen and (max-width: 1100px) {
        align-items: center;
        justify-content: center;
      }
    }
    .no-results {
      color: var(--text);
      padding: 5rem 1rem 0 1rem;
    }
    .load-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 8rem;
      width: 100%;
      .load-more {
        background: var(--primary);
        color: var(--background);
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 2rem 3rem;
        text-transform: uppercase;
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
    .header {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 1rem;
      padding: 0 1rem;
      width: 100%;
      h1 {
        color: var(--text);
        font-size: 2.4rem;
        padding-bottom: 2rem;
        text-align: left;
        width: 100%;
      }
      .filter-wrapper {
        display: flex;
        position: relative;
        width: 100%;
        @media screen and (max-width: 763px) {
          flex-direction: column;
        }
        .reset-filter {
          color: red;
          cursor: pointer;
          display: none;
          font-size: 2rem;
          position: absolute;
          right: 16rem;
          top: 1rem;
          @media screen and (max-width: 763px) {
            right: 1.25rem;
          }
          &.is-active {
            display: block;
          }
        }
        input {
          background: var(--background);
          border: .1rem solid var(--border);
          box-sizing: border-box;
          color: var(--text);
          display: block;
          font-family: var(--font-body);
          font-size: 1.4rem;
          padding: 1rem;
          width: 100%;
          &:focus {
            border-color: var(--text);
            outline: none;
          }
          ::-webkit-input-placeholder {
            color: var(--text);
            opacity: 0.5;
          }
          :-ms-input-placeholder {
            color: var(--text);
            opacity: 0.5;
          }
          ::placeholder {
            color: var(--text);
            opacity: 0.5;
          }
        }
        select {
          background: #FFF url("https://webcdn.hirezstudios.com/smite-esports/assets/select-bkg-gray.png") right no-repeat;
          /* background-color: var(--background); */
          border: .1rem solid var(--border);
          border-radius: 0;
          box-sizing: border-box;
          color: #000;
          display: block;
          font-family: var(--font-body);
          font-size: 1.4rem;
          margin-left: 1.4rem;
          max-width: 13.4rem;
          padding: 1rem;
          position: relative;
          width: 100%;
          -webkit-appearance: none;
          -webkit-border-radius: 0px;
          @media screen and (max-width: 763px) {
            max-width: 100%;
            margin-left: initial;
            margin-top: 2rem;
          }
          &:focus {
            border-color: var(--text);
            outline: none;
          }
          ::-webkit-input-placeholder {
            color: var(--text);
            opacity: 0.5;
          }
          :-ms-input-placeholder {
            color: var(--text);
            opacity: 0.5;
          }
          ::placeholder {
            color: var(--text);
            opacity: 0.5;
          }
          option {
            color: #000;
          }
        }
      }
    }
    .c-NewsCard {
      margin: 1rem;
    }
  }
  header {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 26vw;
    margin-bottom: 5rem;
    min-height: 15rem;
  }
  h4, h5 {
    color: var(--text);
  }
  .related {
    margin: 0 auto 5rem auto;
    max-width: 98rem;
    width: 100%;
    h4 {
      border-left: .2rem solid var(--primary);
      line-height: 3rem;
      margin-bottom: 3rem;
      padding-left: 1rem;
    }
    &__posts {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
`;

class News extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      activeSearch: '',
      filter: 'all',
      isNewsLoading: true,
      newsData: null,
      offset: 18,
      isArchive: 'unknown'
    };
  }

  getURLParameter = (sParam) => {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++)
    {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == sParam)
      {
        return sParameterName[1];
      }
    }
  }

  getNewsData = (call,value) => {
    this.setState({isNewsLoading: true});
    fetch(`https://cms-esports.smitegame.com/wp-json/wp-api/get-posts/1?category=news+${process.env.GATSBY_LEAGUE_NEWS_PAGE_CATEGORY}&${call}=${value}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          newsData: response,
          isNewsLoading: false
        });
      });
    }

  loadMorePosts = () => {
    this.state.offset += 18;
    this.getNewsData('per_page',this.state.offset);
  }

  updateSearch = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        activeSearch: e.target.value,
        offset: 18
      });
      this.getNewsData('search', e.target.value.toLowerCase().replace(/ /g,'+'));
    }
  }

  resetSearch = () => {
    this.setState({
      activeSearch: ''
    });
    this.getNewsData('per_page',this.state.offset);
  }

  filterContent = (e) => {
    if (e.target.value === 'all') {
      this.setState({
        filter: e.target.value
      });
      this.getNewsData('per_page',this.state.offset);
    }
    else {
      this.setState({
        filter: e.target.value
      });
      this.getNewsData('tag',e.target.value);
    }
  }

  componentDidMount() {
    if (this.props.location.pathname === '/news/' || this.props.location.pathname === '/news') {
      if (this.getURLParameter('s')) {
        this.getNewsData('tag',this.getURLParameter('s'))
        this.setState({isArchive: 'yes'});;
      }
      else {
        this.getNewsData('per_page',this.state.offset);
        this.setState({isArchive: 'yes'});
      }
    }
    else {
      window.scrollTo(0,0);
      this.setState({isArchive: 'no'})
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname === '/news/' || this.props.location.pathname === '/news') {
        if (this.getURLParameter('s')) {
          console.log('search');
          this.getNewsData('tag',this.getURLParameter('s'));
          this.setState({isArchive: 'yes'});
        }
        else {
          this.getNewsData('per_page',this.state.offset);
          this.setState({isArchive: 'yes'});
        }
      }
      else {
        window.scrollTo(0,0);
        this.setState({isArchive: 'no'})
      }
    }
  }

  render() {
    return (
      <PageWrapper className="p-news">
      {this.state.isArchive === 'yes' &&
        <div className="news-container">
          <div className="center-wrapper">
            <div className="inner-container">
              <div className="header">
                <h1>Latest News</h1> 
                <div className="filter-wrapper">
                  <input
                    type="text"
                    placeholder="Search News..."
                    onKeyPress={this.updateSearch}
                    onChange={(e)=>this.setState({activeSearch: e.target.value})}
                    value={this.state.activeSearch}  
                  />
                  <div className={`reset-filter ${this.state.activeSearch !== '' ? 'is-active' : ''}`} onClick={this.resetSearch}>✕</div>
                  <select onChange={(e) => this.filterContent(e)}>
                    <option value="all">All</option>
                    <option value="news">News</option>
                    <option value="guides">Guides</option>
                    <option value="rulings">Rulings</option>
                  </select>
                </div>
              </div>
              {this.state.isNewsLoading &&  
                <div className="loading-wrapper">
                  <div id="loader" />
                </div>
              }
              {this.state.newsData && this.state.isNewsLoading === false && this.state.newsData.map(item =>
                <NewsCard
                  date={formatDistanceToNow(
                    new Date(item.timestamp.split(' ')[0]),
                    {addSuffix: true}
                  )}
                  excerpt={item.excerpt}
                  slug={item.slug}
                  thumbnail={(item.format === 'text' || item.format === 'link') ? item.featured_image : `https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`}
                  title={item.title}
                  type={item.format}
                  key={item.slug}
                  url={item.url && item.url}
                />
              )}
              {this.state.isNewsLoading === false && this.state.newsData.length === 0 && 
                <div className="no-results"><p>No Results</p></div>
              }
              {this.state.isNewsLoading === false && this.state.newsData.length >= (this.state.offset) &&
                <div className="load-wrapper">
                  <div onClick={this.loadMorePosts} className="load-more">Load More</div>
                </div>
              }
            </div>
          </div>
        </div>
      }
      {this.state.isArchive === 'no' &&
        <Article slug={this.props.location.pathname.split('/')[2]}/>
      }
      </PageWrapper>
    );
  }
};

export default withContext(News);
