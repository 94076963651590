import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  display: flex;
  height: 28rem;
  max-width: 30rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  a {
    text-decoration: none;
  }
  .thumbnail {
    display: flex;
    height: 20rem;
    overflow: hidden;
    transition: opacity .2s ease-in-out;
    width: 30rem;
    img {
      height: auto;
      object-fit: cover;
      max-height: 20rem;
      width: 100%;
    }
    i {
      color: #FFF;
      font-size: 5rem;
      position: absolute;
    }
    div {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
  .info {
    background: var(--background);
    bottom: -8.5rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    left: 0;
    min-height: 14rem;
    position: absolute;
    transition: all .2s ease-in-out;
    width: 100%;
    h4 {
      color: var(--text);
      font-family: var(--font-body);
      font-size: 1.6rem;
      font-weight: bold;
      height: 3.8rem;
      line-height: 1.2;
      overflow: hidden;
      text-transform: uppercase;
    }
    .meta {
      display: flex;
      margin-top: 1rem;
      strong {
        color: var(--text);
        font-size: 1.2rem;
        font-weight: bold;
        margin-right: 1rem;
        text-transform: uppercase;
      }
      .date {
        color: var(--primary);
        font-size: 1.2rem;
        img {
          margin-right: .4rem;
          transform: translateY(.05rem);
        }
      }
    }
    p {
      color: var(--text);
      font-size: 1.4rem;
      line-height: 1.75;
      margin-top: 1rem;
    }
    @media only screen and (max-width: 768px) {
      p{
        display:none;
        min-height:auto;
      }
    }
    .read-more {
      align-items: flex-end;
      box-sizing: border-box;
      color: var(--primary);
      display: inline-block;
      font-size: 1.4rem;
      font-weight: bold;
      margin-left: auto;
      padding-right: 2rem;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:not(.link):hover {
    .info {
      bottom: 0;
    }
    .thumbnail {
      opacity: .5;
    }
  }
  @media only screen and (max-width: 768px) {
      
      &:hover p {
        display:block;
        min-height:14rem;
      }
    }
    &.video {
      &:hover {
        .info {
          bottom: -8.5rem;
        }
      }
    }
`;